import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import SEO from "../components/SEO/seo"
import ProductList from "../components/Products/ProductList"
import DhmsCircleTimer from "../components/Countdown/DhmsCircleTimer"

import Layout from "../zzz/layout"

const CyberFridayPage = ({ location }) => {
  const [readMore, setReadMore] = useState(false)
  const graphqlData = useStaticQuery(CYBER_MONDAY_PAGE_QUERY)
  const pageData = graphqlData.localWpGraphQlPages
  const title = pageData.title
  const content = pageData.content
  const seo = pageData.seo
  const pageId = pageData.pageId
  const cyberMondayPageIntro = pageData.acf_cyber_monday.cyberMondayPageIntro
  const cyberMondayPageReadMore =
    pageData.acf_cyber_monday.cyberMondayPageReadMore

  return (
    <StyledLayout location={location}>
      <SEO title={title} location={location} post_id={pageId} seo={seo} />
      <PageIntroStyling>
        <StyledH2 className="black-friday-content">
          CYBER MONDAY BED SALE – STARTS IN
        </StyledH2>
        <DhmsCircleTimer endDateString="2023-11-28 00:00:00 +02" />
        {/*<DhmsCircleTimer endDateString="2022-11-28 23:59:59 +02" />*/}
        <StyledH1 dangerouslySetInnerHTML={{ __html: title }} />
        <ContentStyling className="black-friday-content">
          <div dangerouslySetInnerHTML={{ __html: cyberMondayPageIntro }} />
          <button
            className={readMore ? "read-more-hidden" : "read-more-button"}
            onClick={() => setReadMore(true)}
          >
            Read More
          </button>
          <div
            className={readMore ? "" : "read-more-hidden"}
            dangerouslySetInnerHTML={{ __html: cyberMondayPageReadMore }}
          />
        </ContentStyling>
      </PageIntroStyling>
      <ProductList
        showProductDeals
        productList={graphqlData.allWcProducts.nodes}
        productsPerLoad={24}
        sizesFilter={false}
      />
      <ContentStyling
        className="black-friday-content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </StyledLayout>
  )
}

// ====================
//        STYLES
// ====================

const StyledH1 = styled.h1`
  text-transform: uppercase;
  padding-top: 40px;
`
const StyledH2 = styled.h2`
  padding: 40px 0;
`
const PageIntroStyling = styled.div`
  margin: auto;
`
const ContentStyling = styled.div`
  padding: 0px 40px 60px 40px;
  max-width: 1280px;
  a {
    color: #fd3237;
  }
  .read-more-hidden {
    display: none;
  }
  .read-more-button {
    margin-top: 0.5em;
    background-color: #fd3237;
    color: white;
    cursor: pointer;
    display: grid;
    border-radius: 25px;
    background: solid;
    border: none;
    display: inline-block;
    padding: 10px 15px;
    text-transform: uppercase;
    text-align: center;
    transition: all 200ms ease-in-out;
    &:hover {
      background-color: #ed1c24;
    }
  }
`

const StyledLayout = styled(Layout)`
  background: #162232;

  .black-friday-content,
  h1,
  h2,
  h3 {
    color: #9eb5d2;
    margin: auto;
    text-align: center;
  }
`

// ==================
//        QUERY
// ==================

const CYBER_MONDAY_PAGE_QUERY = graphql`
  {
    localWpGraphQlPages(slug: { eq: "cyber-monday-bed-sale" }) {
      pageId
      title
      content
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      acf_cyber_monday {
        cyberMondayPageIntro
        cyberMondayPageReadMore
      }
    }
    ## ProductFilter: All featured products
    allWcProducts(
      filter: {
        status: { eq: "publish" }
        catalog_visibility: { ne: "hidden" }
        featured: { eq: true }
      }
    ) {
      nodes {
        ...ProductCardInfo
      }
    }
  }
`

export default CyberFridayPage
